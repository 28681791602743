<template>
  <!-- begin:: Footer -->
  <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
    <div class="d-flex align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }">
      <div class="text-dark">
        <span class="text-muted font-weight-bold mr-2">
          {{ new Date().getFullYear()}} &nbsp;&copy;&nbsp;
        </span>
        <a href="http://gruparge.com" target="_blank" class="text-dark-75 text-hover-primary footer-company-text">
          <span>GRUP ARGE ENERJİ VE KONTROL SİSTEMLERİ SAN. ve TİC. LTD. ŞTİ.</span>
        </a>
      </div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<style scoped>
  .footer{
    position: absolute;
    bottom:0;
    width: 100%;
  }
  @media screen and (max-width:900px) {
    .footer-company-text{
      font-size: 11px;
    }
  }
  @media screen and (max-width:500px) {
    .footer-company-text{
      font-size: 9px;
    }
  }
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    }
  }
};
</script>


