<template>
    <div class="topbar-item">
        <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle">
            <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hoşgeldin,</span>
            <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{{currentUserPersonalInfo.name}}</span>
            <span class="symbol symbol-35 symbol-light-success">
                <img v-if="true" alt="Pic" src="/media/users/blank.png"/>
                <span v-if="false" class="symbol-label font-size-h5 font-weight-bold">
                    <img v-if="currentUserPersonalInfo.photo" class="symbol-label" :src="currentUserPersonalInfo.photo" alt="user"/>
                    <span v-else>{{getAvatar(currentUserPersonalInfo.name,currentUserPersonalInfo.surname)}}</span>
                </span>
            </span>
        </div>

        <div id="kt_quick_user" ref="kt_quick_user" class="offcanvas offcanvas-right p-10">
            <!--begin::Header-->
            <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                <h3 class="font-weight-bold m-0">Profilim</h3>
                <a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
                    <i class="ki ki-close icon-xs text-muted"></i>
                </a>
            </div>
            <!--end::Header-->
            <!--begin::Content-->
            <perfect-scrollbar class="offcanvas-content pr-5 mr-n5 scroll" style="max-height: 90vh; position: relative;">
                <!--begin::Header-->
                <div class="d-flex align-items-center mt-5">
                    <div class="symbol symbol-100 mr-5">
                        <span class="symbol-label font-size-h5 font-weight-bold">
                            <img class="symbol-label" src="/media/users/blank.png" alt="user"/>
                            <span v-if="false">{{getAvatar(currentUserPersonalInfo.name,currentUserPersonalInfo.surname)}}</span>
                        </span>
                        <i class="symbol-badge bg-success"></i>
                    </div>
                    <div class="d-flex flex-column">
                        <router-link to="/custom-pages/profile" class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">{{ getFullName }}</router-link>
<!--                        <div class="text-muted mt-1">Software Developer</div>-->
                        <div class="navi mt-2">
                            <a class="navi-item">
                                <span class="navi-link p-0 pb-2">
                                    <span class="navi-icon mr-1">
                                        <span class="svg-icon svg-icon-lg svg-icon-primary">
                                            <!--begin::Svg Icon-->
                                            <inline-svg src="media/svg/icons/Communication/Mail-notification.svg"/>
                                            <!--end::Svg Icon-->
                                        </span>
                                    </span>
                                    <span class="navi-text text-muted text-hover-primary">{{ currentUserPersonalInfo.email }}</span>
                                </span>
                            </a>
                        </div>
                        <button class="btn btn-light-primary btn-bold" @click="onLogout">Çıkış Yap</button>
                    </div>
                </div>
                <!--end::Header-->
                <div class="separator separator-dashed mt-8 mb-5"></div>
                <!--begin::Nav-->
                <div class="navi navi-spacer-x-0 p-0">
                    <!--begin::Item-->
                    <router-link to="/builder" @click.native="closeOffcanvas" class="navi-item">
                        <div class="navi-link">
                            <div class="symbol symbol-40 bg-light mr-3">
                                <div class="symbol-label">
                                      <span class="svg-icon svg-icon-md svg-icon-success">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/General/Notification2.svg"/>
                                          <!--end::Svg Icon-->
                                      </span>
                                </div>
                            </div>
                            <div class="navi-text">
                                <router-link to="/profile">
                                    <div class="font-weight-bold">Profilim</div>
                                </router-link>
                            </div>
                        </div>
                    </router-link>
                    <!--end:Item-->
                </div>

            </perfect-scrollbar>
            <!--end::Content-->
        </div>
    </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import {mapGetters} from "vuex";
import {LOGOUT} from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";

export default {
    name: "KTQuickUser",
    data() {
        return {};
    },
    mounted() {
        // Init Quick User Panel
        KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
    },
    methods: {
        onLogout() {
          this.$store.dispatch(LOGOUT);
        },
        closeOffcanvas() {
            new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
        },
        getAvatar(name,surname) {
            return `${String(name).charAt(0).toUpperCase()}${String(surname).charAt(0).toUpperCase()}`;
        }
    },
    computed: {
        ...mapGetters(["currentUserPersonalInfo"]),

        getFullName() {
            return (
                this.currentUserPersonalInfo.name +
                " " +
                this.currentUserPersonalInfo.surname
            );
        }
    }
};
</script>
