<template>
    <ul class="menu-nav">
<!--        <router-link to="/dashboard" v-slot="{ href, navigate, isActive, isExactActive }" >-->
<!--            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">-->
<!--                <a :href="href" class="menu-link" @click="navigate">-->
<!--&lt;!&ndash;                    <i class="menu-icon flaticon2-architecture-and-city"></i>&ndash;&gt;-->
<!--                    <span class="menu-text">Anasayfa</span>-->
<!--                </a>-->
<!--            </li>-->
<!--        </router-link>-->
    </ul>
</template>

<script>
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
