<template>
  <ul class="menu-nav">
    <router-link
      custom
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Anasayfa</span>
        </a>
      </li>
    </router-link>
<!--    <router-link-->
<!--      to="/detail"-->
<!--      v-slot="{ href, navigate, isActive, isExactActive }">-->
<!--      <li-->
<!--        aria-haspopup="true"-->
<!--        data-menu-toggle="hover"-->
<!--        class="menu-item"-->
<!--        :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">-->
<!--        <a :href="href" class="menu-link" @click="navigate">-->
<!--          <i class="menu-icon flaticon-edit-1"></i>-->
<!--          <span class="menu-text">Detay</span>-->
<!--        </a>-->
<!--      </li>-->
<!--    </router-link>-->
    <router-link
      custom
      to="/stations"
      v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-car"></i>
          <span class="menu-text">Santraller</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/areas"
      v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-layers-1"></i>
          <span class="menu-text">Sahalar</span>
        </a>
      </li>
    </router-link>
    <router-link
      custom
      to="/inverters"
      v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-dashboard"></i>
          <span class="menu-text">İnvertörler</span>
        </a>
      </li>
    </router-link>

      <li class="menu-section" v-if="getUserRole == 'admin'">
          <h4 class="menu-text">Admin Paneli</h4>
          <i class="menu-icon flaticon-more-v2"></i>
      </li>
      <router-link custom v-if="getUserRole == 'admin'" to="/station-types" v-slot="{ href, navigate, isActive, isExactActive }">
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon-car"></i>
              <span class="menu-text">Santral Tipleri</span>
              </a>
          </li>
      </router-link>
    <router-link custom v-if="getUserRole == 'admin'" to="/inverter-types" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-dashboard"></i>
          <span class="menu-text">İnvertör Tipleri</span>
        </a>
      </li>
    </router-link>

  </ul>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  },
    computed:{
      ...mapGetters(['getUserRole'])
    }
};
</script>
